import React from 'react';

import './institution.less';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks.jsx';
import { Container, Header, Segment } from 'semantic-ui-react';
import RichTextWidgetView from '@plone/volto-slate/widgets/RichTextWidgetView';
const carouselSchemaBlock = {
  blocks: {
    'd3525d4b-2fbb-4f32-9b03-aeaa5ac82696': {
      '@type': 'listing',
      headlineTag: 'h2',
      slidesToScroll: 1,
      slidesToShow: 1,

      variation: 'carousel_institution',
      querystring: {
        query: [
          {
            i: 'portal_type',
            o: 'plone.app.querystring.operation.selection.any',
            v: ['Image'],
          },
          {
            i: 'path',
            o: 'plone.app.querystring.operation.string.relativePath',
            v: '/',
          },
        ],
        sort_order: 'ascending',
      },
    },
  },
  blocks_layout: { items: ['d3525d4b-2fbb-4f32-9b03-aeaa5ac82696'] },
};

const accordionSchemaBlock = {
  blocks: {
    'd3525d4b-2fbb-4f32-9b03-aeaa5ac82697': {
      '@type': 'listing',
      headlineTag: 'h2',
      descriptionKey: 'program_description',
      variation: 'accordion',
      b_size: 1000,
      batch_size: 1000,
      limit: '1000',
      batch_size: 1000,
      querystring: {
        fullobjects: true,
        b_size: '100',
        limit: '100',
        query: [
          {
            i: 'portal_type',
            o: 'plone.app.querystring.operation.selection.any',
            v: ['program'],
          },
          {
            i: 'path',
            o: 'plone.app.querystring.operation.string.relativePath',
            v: '/',
          },
        ],
        sort_order: 'ascending',
      },
    },
  },
  blocks_layout: { items: ['d3525d4b-2fbb-4f32-9b03-aeaa5ac82697'] },
};

const InstitutionView = (props) => {
  const { location, contact, social, full_description, study_programmes } =
    props.content || {};

  return (
    <div className="institution">
      <h2 style={{ textAlign: 'center' }}>{props?.content?.title}</h2>
      <RenderBlocks
        {...props}
        content={{ ...(props.content || {}), ...carouselSchemaBlock }}
      />

      <div className="grid-container">
        <div className="left-column">
          <div className="institution-metadata">
            <Header>Location</Header>
            <RichTextWidgetView value={location} />
          </div>

          <div className="institution-metadata">
            <Header>Contact</Header>
            <RichTextWidgetView value={contact} />
          </div>
          <div className="institution-metadata">
            <Header>Keep updated</Header>
            <RichTextWidgetView value={social} />
          </div>
        </div>
        <div className="center-column">
          <RichTextWidgetView value={full_description} />
        </div>
      </div>
      <div className="grid-container">
        <div className="left-column"></div>
        <div className="center-column">
          <div className="institution-links institution-spacing">
            {}
            <h2 className="facultiesTitle ">What you can study here</h2>
            <RichTextWidgetView value={study_programmes} />
          </div>
          <div className="institution-programmes-heading">
            Click and browse through the list of study programmes offered at the
            institution.
          </div>
          <RenderBlocks
            {...props}
            content={{
              ...(props.content || {}),
              ...accordionSchemaBlock,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InstitutionView;
