const Schema = (props) => ({
  title: 'Programme date',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'deadline', 'start'],
    },
  ],

  properties: {
    title: {
      type: 'string',
      title: 'Date labels',
      description: 'Fall | Autumn | Applications continually accepted',
    },
    deadline: {
      widget: 'date',
      title: 'Application deadline',
    },
    start: {
      widget: 'date',
      title: 'Start date',
    },
  },

  required: [],
});

export default Schema;
