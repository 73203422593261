import React from 'react';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import config from '@plone/volto/registry';
import Slider from 'react-slick';

const Arrow = () => {
  return (
    <svg
      width="48"
      height="24.999999999999996"
      xmlns="http://www.w3.org/2000/svg"
      stroke="null"
      style={{ vectorEffect: 'non-scaling-stroke' }}
    >
      <title stroke="null">arrow-slider-icon</title>
      <desc stroke="null">Created with Sketch.</desc>

      <g stroke="null">
        <title stroke="null">background</title>
        <rect
          stroke="null"
          fill="none"
          id="canvas_background"
          height="27"
          width="50"
          y="-1"
          x="-1"
        />
      </g>
      <g stroke="null">
        <title stroke="null">Layer 1</title>
        <g stroke="null" fill-rule="evenodd" fill="none" id="Symbols">
          <line
            stroke="#ffffff"
            stroke-linecap="undefined"
            stroke-linejoin="undefined"
            id="svg_7"
            y2="12.408856"
            x2="46.955209"
            y1="12.408856"
            x1="0.492582"
            fill-opacity="null"
            stroke-width="2"
            fill="none"
          />
          <line
            stroke-linecap="undefined"
            stroke-linejoin="undefined"
            id="svg_10"
            y2="24.375986"
            x2="34.512033"
            y1="11.977613"
            x1="46.910406"
            fill-opacity="null"
            stroke-width="2"
            stroke="#ffffff"
            fill="none"
          />
          <line
            transform="rotate(-90 40.86047363281251,6.684283256530759) "
            stroke-linecap="undefined"
            stroke-linejoin="undefined"
            id="svg_11"
            y2="12.88347"
            x2="34.661287"
            y1="0.485097"
            x1="47.059659"
            fill-opacity="null"
            stroke-width="2"
            stroke="#ffffff"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-arrow next-arrow"
      onClick={onClick}
      style={{
        position: 'absolute',
        bottom: '400px',
        right: '10px',
        transform: 'translateY(-50%)',
        zIndex: 10,
        cursor: 'pointer',
      }}
    >
      <Arrow />
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-arrow prev-arrow"
      onClick={onClick}
      style={{
        position: 'absolute',
        bottom: '400px',
        left: '10px',
        transform: 'translateY(-50%) rotate(180deg)',
        zIndex: 10,
        cursor: 'pointer',
      }}
    >
      <Arrow />
    </div>
  );
};

const ImageGalleryTemplate = ({ items }) => {
  const imagesInfo = items
    .filter((i) => i.exclude_from_nav !== true)
    .map((item) => {
      return {
        title: item.title,
        original: `${flattenToAppURL(item['@id'])}/@@images/${
          item.image_field
        }/large`,
        thumbnail: `${flattenToAppURL(item['@id'])}/@@images/${
          item.image_field
        }/thumb`,
        description: (
          <>
            <div className="title">{item.title}</div>
            <div className="description">{item.description}</div>
            <div className="download">
              <a
                href={`${flattenToAppURL(item['@id'])}/@@images/${
                  item.image_field
                }/huge`}
                download
              >
                download
              </a>
            </div>
            <div className="credits">{item.copyright_and_sources}</div>
          </>
        ),
      };
    });

  return (
    <div
      className="institution-slider"
      style={{
        padding: '20px',
        boxSizing: 'border-box',
        overflow: 'hidden',
        margin: 'auto',
      }}
    >
      <Slider
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay={true}
        autoplaySpeed={2000}
        cssEase={'linear'}
        nextArrow={<CustomNextArrow />}
        prevArrow={<CustomPrevArrow />}
      >
        {imagesInfo.map((image, index) => (
          <div
            className="institution-slide-image"
            key={index}
            style={{
              margin: '0 auto',
              padding: '0 20px',
              width: '100%',
            }}
          >
            <img
              src={image.original}
              alt={image.title}
              style={{
                borderRadius: '10px',

                backgroundPosition: '50% !important',
              }}
            />
            <div className="institution-slide-description">
              <div className="institution-slides-counter">
                <span className="institution-current-slide">{index + 1}</span>
                <span className="institution-separator"> / </span>
                <span className="institution-slides-number">
                  {imagesInfo.length}
                </span>
              </div>
              <p>{image.title}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

ImageGalleryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ImageGalleryTemplate;
