import React from 'react';
import './hero-block.less';
import { HeroImageSchema } from './schema.js';
import isFunction from 'lodash/isFunction';
import { BlockDataForm, SidebarPortal } from '@plone/volto/components';
import FallBackImage from './student-testimonials-top-image.jpg';
function View(props) {
  const { data = {}, block = null, onChangeBlock, selected } = props;

  const { title, description } = props.content;
  const schema = React.useMemo(() => {
    if (isFunction(HeroImageSchema)) {
      return HeroImageSchema(props);
    }
    return HeroImageSchema;
  }, [props]);
  return (
    <>
      <SidebarPortal selected={selected}>
        <BlockDataForm
          block={block}
          schema={schema}
          title={HeroImageSchema.title}
          onChangeBlock={props.onChangeBlock}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
        />
      </SidebarPortal>
      <div className="hero-block front">
        <div class="section-breaker top snipcss-JonT8">
          <div
            class="section-breaker-img slides hero-initialized hero-slider"
            style={{
              backgroundImage: `url(${
                data?.hero_image
                  ? data?.hero_image + '/@@images/image/teaser'
                  : FallBackImage
              })`,
            }}
          >
            <div class="hero-list draggable">
              <div class="hero-track style-NCF3v" id="style-NCF3v">
                <div
                  class="hero-slide hero-current hero-active style-5bIA7"
                  data-hero-index="0"
                  aria-hidden="false"
                  tabindex="0"
                  id="style-5bIA7"
                ></div>
              </div>
            </div>
          </div>
          <div class="section-breaker-body">
            <div class="title">
              <span>{title?.split(' ')?.[0]}</span>
              <span class="q">{title?.split(' ')?.slice(1).join(' ')}</span>
            </div>
            <div class="description">{description}</div>
            <div class="action">
              <a href="#article">Read more</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default View;
