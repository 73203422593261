import PropTypes from 'prop-types';
import { useRef, useCallback, useEffect } from 'react';
import { ConditionalLink, Component } from '@plone/volto/components';
import { FormattedDate } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

import Slider from 'react-slick';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const CarouselTemplate = (props) => {
  const { items, linkTitle, linkHref, isEditMode } = props;
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const sliderRef = useRef();

  const next = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  const previous = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    ref: sliderRef,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    const headlineElement = document.querySelector('.carousel .headline');
    const controlsElement = document.querySelector('.carousel-controls');
    if (headlineElement && controlsElement) {
      headlineElement.appendChild(controlsElement);
    }
  }, [props]);
  return (
    <div>
      <div className="carousel-container">
        <Slider {...settings}>
          {items.map((item, index) => {
            return (
              <div key={item?.cmf_uid}>
                <p className="carousel-index">
                  <strong style={{ color: 'black' }}>{index + 1}</strong> /{' '}
                  {items?.length}
                </p>
                <ConditionalLink item={item} condition={!isEditMode}>
                  <div className="carousel-item">
                    {item.image_field !== '' && (
                      <Component
                        componentName="PreviewImage"
                        item={item}
                        alt=""
                        className="carousel-item-image"
                      />
                    )}
                    {item?.Date && (
                      <span className="news-date">
                        <FormattedDate date={item?.Date} format={dateOptions} />
                      </span>
                    )}
                    <div className="carousel-item-content">
                      {item?.head_title && (
                        <div className="carousel-item-headline">
                          {item.head_title}
                        </div>
                      )}

                      <h3 className="carousel-item-headline">{item?.title}</h3>
                      {!item.hide_description && (
                        <p className="carousel-item-description">
                          {item?.description}
                        </p>
                      )}
                    </div>
                  </div>
                </ConditionalLink>
              </div>
            );
          })}
        </Slider>
        <div className="carousel-controls">
          <button
            className="button"
            onClick={previous}
            aria-label="Previous slide"
          >
            <LeftOutlined />
          </button>
          <button className="button" onClick={next} aria-label="Next slide">
            <RightOutlined />
          </button>
        </div>
      </div>
      {link && <div className="footer">{link}</div>}
    </div>
  );
};

CarouselTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default CarouselTemplate;
