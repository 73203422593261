import React, { useEffect, useState } from 'react';
import { Grid, Header } from 'semantic-ui-react';

const YouTubeVideoGrid = ({ items }) => {
  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    const fetchVideoData = async () => {
      const fetchedVideos = await Promise.all(
        items.map(async (item) => {
          try {
            const response = await fetch(
              item['getURL']?.replace(
                /^(https?:\/\/[^/]+)(\/.*)?$/,
                '$1/++api++$2',
              ),
            );
            const data = await response.json();
            return {
              ...item,
              youtube_embed_url: data.youtube_embed_url || '',
            };
          } catch (error) {
            console.error(
              `Error fetching video data for ${item['@id']}:`,
              error,
            );
            return {
              ...item,
              youtube_embed_url: '',
              title: 'Error',
              description: '',
            };
          }
        }),
      );
      setVideoData(fetchedVideos);
    };

    fetchVideoData();
  }, [items]);

  return (
    <div style={{ margin: '10px' }}>
      <Grid stackable doubling columns={2}>
        {videoData.map((video, index) => (
          <Grid.Column key={index}>
            <div style={{ paddingTop: '30px' }}>
              {video.youtube_embed_url ? (
                <iframe
                  width="100%"
                  height="315"
                  src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                    video.youtube_embed_url,
                  )}`}
                  title={video.title || 'YouTube Video'}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                <p>Video not available</p>
              )}
              <Header as="h3" style={{ marginTop: '10px' }}>
                {video.title}
              </Header>
              <p>{video.description}</p>
            </div>
          </Grid.Column>
        ))}
      </Grid>
    </div>
  );
};

const getYouTubeVideoId = (url = '') => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([^&\s]+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

export default YouTubeVideoGrid;
