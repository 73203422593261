import React from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { Container, Header, Segment } from 'semantic-ui-react';
import './campus.less';
import RichTextWidgetView from '@plone/volto-slate/widgets/RichTextWidgetView';

const mapContainerStyle = {
  height: '300px',
  width: '100%',
};

const CampusMap = ({ latitude, longitude, title, address }) => {
  const [selected, setSelected] = React.useState(false);

  return (
    <LoadScript googleMapsApiKey={process.env.RAZZLE_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={{ lat: latitude, lng: longitude }}
        zoom={15}
      >
        <Marker
          position={{ lat: latitude, lng: longitude }}
          onClick={() => setSelected(true)}
        />

        {selected && (
          <InfoWindow
            position={{ lat: latitude, lng: longitude }}
            onCloseClick={() => setSelected(false)}
          >
            <div>
              <h3>{title}</h3>
              <p>{address}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

const CampusView = (props) => {
  const { title, text, longitude, latitude, address } = props?.content || {};

  return (
    <div className="campus-view">
      <Container style={{ margin: '0 auto', width: '100% !important' }}>
        <Segment>
          <Header as="h1">{title}</Header>
        </Segment>
        <Segment>
          <RichTextWidgetView value={text} />
        </Segment>

        {/* Harta Google cu punct și detalii */}
        <Segment>
          <CampusMap
            latitude={parseFloat(latitude)}
            longitude={parseFloat(longitude)}
            title={title}
            address={address}
          />
        </Segment>
      </Container>
    </div>
  );
};

export default CampusView;
