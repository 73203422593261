import React, { useEffect } from 'react';
import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';
import logo from './logo/MoEDS-logo-72.png';
import fbIcon from './social-media/fb-icon.png';
import RichTextWidgetView from '@plone/volto-slate/widgets/RichTextWidgetView';
import { connect } from 'react-redux';
import { getContent } from '@plone/volto/actions';
/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ siteData, getContent, footerActions }) => {
  const footerText = siteData?.footer_text || null;

  useEffect(() => {
    getContent('/', null, 'site');
  }, [getContent]);
  return (
    <footer id="footer">
      <Container className="footer">
        <div className="footer-row">
          <img className="logo" src={logo} alt="stdk-logo" />
          <div>
            <div className="footer-text">
              <RichTextWidgetView value={footerText} />
            </div>
          </div>
        </div>
        <div className="footer-separator"></div>
        <div className="footer-row">
          <div></div>
          <ol>
            {footerActions.map((fa) => {
              if (fa.id === 'social_media')
                return (
                  <li>
                    <a href={fa.url}>
                      {fa.title}{' '}
                      <span>
                        <img
                          className="social-media"
                          src={fbIcon}
                          alt="socialmedia"
                        />
                      </span>
                    </a>
                  </li>
                );
              return (
                <li>
                  <a href={fa.url}>{fa.title}</a>
                </li>
              );
            })}
          </ol>
        </div>
      </Container>
    </footer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default connect(
  (state) => {
    return {
      footerActions: state?.actions?.actions?.footer_actions || [],
      siteData: state.content.subrequests?.site?.data,
    };
  },
  { getContent },
)(Footer);
