import { defineMessages } from 'react-intl';
import { addStyling } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';
import config from '@plone/volto/registry';

const messages = defineMessages({
  Type: {
    id: 'STDK Button',
    defaultMessage: 'STDK Button',
  },
  Label: {
    id: 'Label',
    defaultMessage: 'Label',
  },
  Link: {
    id: 'Link',
    defaultMessage: 'Link',
  },
  Headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
  Description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  backgroundColor: {
    id: 'Background color',
    defaultMessage: 'Background color',
  },
});

const colors = [
  { name: 'transparent', label: 'Transparent' },
  { name: 'grey', label: 'Grey' },
];
config.blocks?.blocksConfig?.[formData['@type']]?.colors;

const defaultBGColor =
  config.blocks?.blocksConfig?.[formData['@type']]?.defaultBGColor;

const StdkButtonSchema = ({ intl }) => {
  const schema = {
    title: intl.formatMessage(messages.Type),
    block: 'stdkButton',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['label', 'href', 'title', 'description'],
      },
    ],

    properties: {
      label: {
        title: intl.formatMessage(messages.Label),
      },
      href: {
        title: intl.formatMessage(messages.Link),
        widget: 'url',
      },
      title: {
        title: intl.formatMessage(messages.Headline),
        default: intl.formatMessage(messages.Headline),
      },
      description: {
        title: intl.formatMessage(messages.Description),
        default: intl.formatMessage(messages.Description),
      },
    },
    required: [],
  };
  addStyling({ schema, intl });
  schema.properties.styles.schema.fieldsets[0].fields = [
    ...schema.properties.styles.schema.fieldsets[0].fields,
    'backGroundColor',
  ];
  schema.properties.styles.schema.properties['backGroundColor'] = {
    widget: 'color_picker',
    title: intl.formatMessage(messages.backgroundColor),
    colors,
    default: defaultBGColor,
  };
  return schema;
};

export default StdkButtonSchema;
