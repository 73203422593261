import React from 'react';
import './stdk-button.scss';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';

const StdkButtonView = ({ data, isEditMode }) => {
  const [hasLink, setHasLink] = React.useState(false);
  const href = data.href?.[0]?.['@id'] || data.href;

  React.useEffect(() => {
    if (isEditMode) {
      setHasLink(false);
    } else {
      if (data.href) {
        if (data.href && data.href.length > 0) {
          setHasLink(true);
        }
        if (data.href.length === 0) {
          setHasLink(false);
        }
      }
    }
  }, [isEditMode, data.href]);

  const url = hasLink && isInternalURL(href) ? flattenToAppURL(href) : href;
  return (
    <div
      className="stdk-button-wrapper"
      style={{
        backgroundColor:
          data?.styles?.backGroundColor === 'grey' ? '#F5F5F5' : 'transparent',
      }}
    >
      <div>
        {data?.title && <p className="button-title">{data.title}</p>}
        {data?.description && (
          <p className="button-description">{data.description}</p>
        )}
      </div>
      <div className="stdk-button">
        <a href={url}>{data.label || ''}</a>
      </div>
    </div>
  );
};

export default StdkButtonView;
