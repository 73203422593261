// See Customization for more info
import React from 'react';
import PropTypes from 'prop-types';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const DefaultTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <UniversalLink href={href}>{linkTitle || href}</UniversalLink>;
  }

  return (
    <>
      <div className="items">
        {items.map((item) => {
          return (
            <div className="listing-item" key={item['@id']}>
              <ConditionalLink item={item} condition={!isEditMode}>
                <div className="listing-body">
                  <>
                    <h2>{item.title ? item.title : item.id}</h2>
                    {item?.start && (
                      <div className="event-from">
                        {`${new Date(item?.start).toLocaleDateString('en-us', {
                          month: 'short',
                          day: '2-digit',
                          year: 'numeric',
                        })} from ${new Date(item?.start)
                          .getHours()
                          .toString()
                          .padStart(2, '0')}:${new Date(item?.start)
                          .getMinutes()
                          .toString()
                          .padStart(2, '0')}`}
                        {item.end && (
                          <>
                            {' '}
                            to{' '}
                            {` ${new Date(item?.end)
                              .getHours()
                              .toString()
                              .padStart(2, '0')}:${new Date(item?.end)
                              .getMinutes()
                              .toString()
                              .padStart(2, '0')}`}
                          </>
                        )}
                        {item.location && ' - ' + item.location}
                      </div>
                    )}
                  </>
                  <p className="event-description">{item.description}</p>
                </div>
              </ConditionalLink>
            </div>
          );
        })}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};
DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default DefaultTemplate;
