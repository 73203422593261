import { addStyling } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';
import { createIntl, createIntlCache } from 'react-intl';
import config from '@plone/volto/registry';

const colors = [
  { name: 'transparent', label: 'Transparent' },
  { name: 'grey', label: 'Grey' },
];
config.blocks?.blocksConfig?.[formData['@type']]?.colors;

const defaultBGColor =
  config.blocks?.blocksConfig?.[formData['@type']]?.defaultBGColor;

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: 'en',
    messages: {},
    formattedMessage: () => {},
  },
  cache,
);

export const HeroImageSchema = () => {
  const schema = {
    title: 'Hero Image Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['hero_image'],
      },
    ],
    properties: {
      hero_image: {
        title: 'Hero Image',
        description: 'Upload or select an image to display as the hero image.',
        widget: 'image',

        allowExternals: false,
        required: true,
      },
    },
    required: [],
  };
  addStyling({ schema, intl });
  schema.properties.styles.schema.fieldsets[0].fields = [
    ...schema.properties.styles.schema.fieldsets[0].fields,
    'backGroundColor',
  ];
  schema.properties.styles.schema.properties['backGroundColor'] = {
    widget: 'color_picker',
    title: 'Background Color',
    colors,
    default: defaultBGColor,
  };
  return schema;
};
