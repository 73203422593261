import React from 'react';
import { flushSync } from 'react-dom';
import SearchInputProgrammes from './SearchInputProgrammes';
import Facets from '@plone/volto/components/manage/Blocks/Search/components/Facets.jsx';
import './programmes-facets.scss';

const FacetWrapper = ({ children, facetSettings = {}, visible }) => {
  return <div>{children}</div>;
};

const ProgrammesFacets = (props) => {
  const {
    data,
    children,
    totalItems,
    facets,
    setFacets,
    onTriggerSearch,
    searchedText,
    querystring = {},
  } = props;
  const { showSearchButton } = data;
  const isLive = !showSearchButton;
  return (
    <div className="search-programmes-variation">
      {data.headline && (
        <div>
          <h2 className="headline">{data.headline}</h2>
        </div>
      )}
      <div className="search-facets-container">
        <div className="facets-programmes">
          {data.facetsTitle && <h3>{data.facetsTitle}</h3>}
          <Facets
            data={data}
            querystring={querystring}
            facets={facets}
            setFacets={(f) => {
              flushSync(() => {
                setFacets(f);
                onTriggerSearch(searchedText || '', f);
              });
            }}
            facetWrapper={FacetWrapper}
          />
        </div>
        <SearchInputProgrammes {...props} isLive={isLive} />
      </div>
      <div class="results-box">
        <p class="results-summary">
          Found <span class="results-count">{totalItems}</span> programmes.
        </p>
        <p class="results-details">All are shown below.</p>
        <p class="query-examples">
          Query examples: <span>"Master"</span>, <span>"Erasmus"</span>,{' '}
          <span>"Lyngby"</span>
        </p>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default ProgrammesFacets;
