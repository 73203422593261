import React from 'react';
import { Input } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import loupeSVG from '@plone/volto/icons/zoom.svg';

const SearchInputProgrammes = (props) => {
  const { searchText, setSearchText, isLive, onTriggerSearch } = props;

  return (
    <div className="search-input-programmes">
      <Input
        id={`${props.id}-searchtext`}
        value={searchText}
        placeholder="Search"
        onKeyPress={(event) => {
          if (isLive || event.key === 'Enter') onTriggerSearch(searchText);
        }}
        onChange={(event, { value }) => {
          setSearchText(value);
          if (isLive) {
            onTriggerSearch(value);
          }
        }}
      />
      <Icon name={loupeSVG} />
    </div>
  );
};

export default SearchInputProgrammes;
