import React, { useState } from 'react';
import { flushSync } from 'react-dom';
import SearchInputAdvancedSearch from './SearchInputAdvancedSearch';
import Facets from '@plone/volto/components/manage/Blocks/Search/components/Facets.jsx';
import { useHistory } from 'react-router-dom';
import './advanced-search.scss';

const FacetWrapper = ({ children }) => {
  return <div className="facet-wrapper">{children}</div>;
};

const AdvancedSearchProgrammes = (props) => {
  const {
    data,
    facets,
    setFacets,
    onTriggerSearch,
    searchedText,
    querystring = {},
  } = props;

  const { showSearchButton } = data;
  const isLive = !showSearchButton;

  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const history = useHistory();

  const redirectSearch = () => {
    const currentQuery = window.location.search;
    history.push(`/portal${currentQuery}`);
  };

  return (
    <div className="advanced-search-container">
      {data.headline && (
        <div className="headline-container">
          <h1 className="headline">{data.headline}</h1>
        </div>
      )}
      <div className="advanced-search-body">
        <SearchInputAdvancedSearch
          {...props}
          isLive={isLive}
          onEnter={redirectSearch}
        />
        <button
          className="advanced-search-button"
          onClick={() => setAccordionOpen(!isAccordionOpen)}
        >
          ADVANCED SEARCH {isAccordionOpen ? <span>▲</span> : <span>▼</span>}
        </button>

        {isAccordionOpen && (
          <div>
            <div className="facet">
              <Facets
                data={data}
                querystring={querystring}
                facets={facets}
                setFacets={(f) => {
                  flushSync(() => {
                    setFacets(f);
                    onTriggerSearch(searchedText || '', f);
                  });
                }}
                facetWrapper={FacetWrapper}
              />
            </div>
            <div className="facet-search-button-container">
              <button className="facet-search-button" onClick={redirectSearch}>
                Search
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdvancedSearchProgrammes;
