import React from 'react';
import { Input } from 'semantic-ui-react';

const SearchInputAdvancedSearch = (props) => {
  const {
    searchText,
    setSearchText,
    isLive,
    onTriggerSearch,
    onEnter = () => {},
  } = props;

  return (
    <div className="search-input-advanced">
      <Input
        id={`${props.id}-searchtext`}
        value={searchText}
        placeholder="What do you want to study?"
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onTriggerSearch(searchText);
            onEnter();
          }
        }}
        onChange={(event, { value }) => {
          setSearchText(value);
          if (isLive) {
            onTriggerSearch(value);
          }
        }}
      />
    </div>
  );
};

export default SearchInputAdvancedSearch;
