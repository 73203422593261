import React, { useState, useEffect } from 'react';
import './program.less';
import { getContent } from '@plone/volto/actions';
import { connect } from 'react-redux';
import RichTextWidgetView from '@plone/volto-slate/widgets/RichTextWidgetView';
function formatDate(date) {
  const options = { month: 'short', day: '2-digit' };
  return date.toLocaleDateString('en-US', options);
}
function ProgramView(props) {
  const { getContent, campusData } = props;

  const {
    title,
    degree,
    specialisation,
    credits,
    program_subject,
    duration,
    tuition_per_term__non_eu_eaa_ch,
    tuition_per_term__eu_eaa_ch,
    programme_url,
    program_description,
    programme_dates,
    joint_erasmus_programme,
  } = props?.content || {};

  const campus = props?.content?.parent;

  useEffect(() => {
    if (props?.content?.parent?.['@id'])
      getContent(
        props?.content?.parent?.['@id']?.replace(
          /^(https?:\/\/[^/]+)(\/.*)?$/,
          '$1/++api++$2',
        ),
        null,
        props.content?.['@id'],
      );
  }, [getContent]);

  return (
    <div className="program">
      <div
        id="content-core"
        className="cell width-full position-0 snipcss-LhNT8"
      >
        <div id="program-details">
          {title && <h4 className="program-title">{title}</h4>}

          {degree && (
            <h5 className="program-sub">
              {degree.title} of {specialisation}, {credits} ECTS
            </h5>
          )}
          {program_subject?.map((p) => (
            <span className="program-subjects">{p.title}</span>
          ))}

          <br />
          <br />
          <table className="program-table">
            <tbody>
              <tr>
                <td>
                  <b>Institution</b>
                </td>
                <td>
                  <a
                    href={campusData?.parent?.['@id']}
                    title="University of Southern Denmark (SDU)"
                  >
                    {campusData?.parent?.title}
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Campus</b>
                </td>
                <td>
                  <a href={campus?.['@id']} title="Odense">
                    {campus?.title}
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Duration</b>
                </td>
                <td>{duration}</td>
              </tr>
              <tr>
                <td>
                  <b>Tuition per term (Non-EU/EAA/CH)</b>
                </td>
                <td>{tuition_per_term__non_eu_eaa_ch}</td>
              </tr>
              <tr>
                <td>
                  <b>Tuition per term (EU/EAA/CH)</b>
                </td>
                <td>{tuition_per_term__eu_eaa_ch}</td>
              </tr>
              {joint_erasmus_programme === true && (
                <tr>
                  <td>
                    <b>Erasmus</b>
                  </td>
                  <td>{joint_erasmus_programme ? 'YES' : 'NO'}</td>
                </tr>
              )}
              {programme_url && (
                <tr>
                  <td>
                    <b>Website</b>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="external-link truncate-text"
                      href={programme_url}
                    >
                      {programme_url}
                    </a>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="program-description">
            <RichTextWidgetView value={program_description} />
          </div>
          {programme_dates?.data?.length > 0 && (
            <div class="program-dates well">
              {programme_dates?.data?.map((d) => (
                <>
                  <h4>{d.title}</h4>
                  <h5>Application date</h5>
                  <span>{formatDate(new Date(d.start))}</span>
                  <h5>Start date</h5>
                  <span>{formatDate(new Date(d.deadline))}</span>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state, props) => ({
    campusData: state.content.subrequests?.[props?.content?.['@id']]?.data,
    state,
    props,
  }),
  { getContent },
)(ProgramView);
