import React, { useState } from 'react';
import { ConditionalLink } from '@plone/volto/components';
import './table-template.scss';

const TableTemplate = ({ items, isEditMode }) => {
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [sortedItems, setSortedItems] = useState(items);

  const sortItems = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    const sorted = [...sortedItems].sort((a, b) => {
      // Convert to numbers if the key is "credits"
      const aValue = key === 'credits' ? Number(a[key] || 0) : a[key];
      const bValue = key === 'credits' ? Number(b[key] || 0) : b[key];

      // Numeric sorting for numbers
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return direction === 'asc' ? aValue - bValue : bValue - aValue;
      }

      // String sorting for other cases
      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setSortConfig({ key, direction });
    setSortedItems(sorted);
  };

  return (
    <div className="table-template">
      <table className="results-table">
        <thead>
          <tr>
            <th>
              <button onClick={() => sortItems('title')}>
                Title{' '}
                {sortConfig.key === 'title'
                  ? sortConfig.direction === 'asc'
                    ? '▲'
                    : '▼'
                  : ''}
              </button>
            </th>
            <th>
              <button onClick={() => sortItems('program_subject_title')}>
                Subject{' '}
                {sortConfig.key === 'program_subject_title'
                  ? sortConfig.direction === 'asc'
                    ? '▲'
                    : '▼'
                  : ''}
              </button>
            </th>
            <th>
              <button onClick={() => sortItems('degree_title')}>
                Degree{' '}
                {sortConfig.key === 'degree_title'
                  ? sortConfig.direction === 'asc'
                    ? '▲'
                    : '▼'
                  : ''}
              </button>
            </th>
            <th>
              <button onClick={() => sortItems('institution_title')}>
                Institution{' '}
                {sortConfig.key === 'institution_title'
                  ? sortConfig.direction === 'asc'
                    ? '▲'
                    : '▼'
                  : ''}
              </button>
            </th>
            <th>
              <button onClick={() => sortItems('credits')}>
                ECTS{' '}
                {sortConfig.key === 'credits'
                  ? sortConfig.direction === 'asc'
                    ? '▲'
                    : '▼'
                  : ''}
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedItems.map((item) => (
            <tr key={item['@id']}>
              <td
                className={item.review_state !== 'published' ? 'grey-text' : ''}
              >
                <ConditionalLink item={item} condition={!isEditMode}>
                  {item.title}
                </ConditionalLink>
              </td>
              <td
                className={item.review_state !== 'published' ? 'grey-text' : ''}
              >
                {item.program_subject_title?.[0] || 'N/A'}
              </td>
              <td
                className={item.review_state !== 'published' ? 'grey-text' : ''}
              >
                {item.degree_title || 'N/A'}
              </td>
              <td
                className={item.review_state !== 'published' ? 'grey-text' : ''}
              >
                {item.institution_title ? (
                  <a href={`portal/${item.institution}`}>
                    <p>{item.institution_title}</p>
                  </a>
                ) : (
                  'N/A'
                )}
              </td>
              <td
                className={item.review_state === 'published' ? '' : 'grey-text'}
              >
                {item.credits || 'N/A'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableTemplate;
