import React from 'react';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import { defineMessages } from 'react-intl';
import StdkButtonSchema from './schema';
import StdkButtonView from './StdkButtonView';

const messages = defineMessages({
  Type: {
    id: 'STDK Button',
    defaultMessage: 'STDK Button',
  },
});

const StdkButtonEdit = (props) => {
  const { data, block, onChangeBlock, selected, intl } = props;
  const schema = StdkButtonSchema({ intl });
  return (
    <>
      <StdkButtonView {...props} isEditMode />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          title={intl.formatMessage(messages.Type)}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
          fieldIndex={data.index}
          block={block}
        />
      </SidebarPortal>
    </>
  );
};

export default StdkButtonEdit;
