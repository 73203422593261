/**
 * Body video block.
 * @module components/manage/Blocks/Video/Body
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isInternalURL } from '@plone/volto/helpers';
import { getFieldURL } from '@eeacms/volto-nextcloud-video-block/helpers';
import players from './players';
import logoLeft from '../../../../../images/SID-white.svg';
/**
 * Body video block class.
 * @class Body
 * @extends Component
 */
const ViewBody = (props) => {
  const { data } = props;
  const { branding } = data;

  const previewImage = getFieldURL(data.preview_image);
  const url = getFieldURL(data.url);

  let placeholder = previewImage
    ? isInternalURL(previewImage)
      ? `${previewImage}/@@images/image`
      : previewImage
    : null;

  const ref = React.createRef();
  const onKeyDown = (e) => {
    if (e.nativeEvent.keyCode === 13) {
      ref.current.handleClick();
    }
  };

  const embedSettings = {
    placeholder,
    icon: 'play',
    defaultActive: false,
    autoplay: false,
    aspectRatio: '16:9',
    tabIndex: 0,
    onKeyPress: onKeyDown,
    ref: ref,
  };
  // for future there can be more embed players
  const allowedPlayersList = ['nextCloud'];
  const myDivRef = useRef(null);
  // only use allowed embed players from all that exist
  const allowedPlayers = allowedPlayersList.reduce((acc, playerName) => {
    const player = players[playerName];
    return {
      ...acc,
      [playerName]: player,
    };
  }, {});

  const SelectedPlayerComponent = allowedPlayersList.reduce(
    (acc, currentPlayerName) => {
      // eslint-disable-next-line no-unused-expressions
      const result = url?.match(currentPlayerName)
        ? allowedPlayers[currentPlayerName]
        : acc;
      return result;
    },
    allowedPlayers.nextCloud,
  );
  useEffect(() => {
    const newParent = document.getElementById('main');
    let movedDiv = null;

    // Injectarea stilului
    const styleElement = document.createElement('style');
    styleElement.textContent = `
      .hamburger-wrapper button:not([aria-label='Close menu']) {
        display: none !important;
      }
      .video-hero .hamburger-wrapper button:not([aria-label='Close menu']) {
        display: block !important;
      }
    `;
    document.head.appendChild(styleElement);

    if (newParent && myDivRef.current) {
      movedDiv = myDivRef.current;
      newParent.insertBefore(movedDiv, newParent.firstChild);
    }

    const hamburger = document.querySelector(
      '.hamburger-wrapper.mobile.tablet.only',
    );
    const video = document.querySelector('.video-hero');

    if (hamburger && video) {
      const clonedHamburger = hamburger.cloneNode(true);

      const buttonInsideClonedHamburger =
        clonedHamburger.querySelector('button');
      if (buttonInsideClonedHamburger) {
        buttonInsideClonedHamburger.addEventListener('click', (e) => {
          hamburger.querySelector('button').click();
          e.preventDefault();
        });
      }

      video.insertBefore(clonedHamburger, video.firstChild);
    }

    return () => {
      if (styleElement) {
        document.head.removeChild(styleElement);
      }

      if (newParent && movedDiv) {
        newParent.removeChild(movedDiv);
      }
    };
  }, []);

  return (
    <div ref={myDivRef} className="video-hero">
      {url && (
        <div
          className={cx('video-inner', 'video-container', {
            'full-width': data.align === 'full',
            'not-branding': branding !== true,
          })}
        >
          {branding && (
            <div className="logo-container ">
              <a
                href="/"
                className="logo-href computer large screen widescreen only"
              >
                <img className="logo-header" src={logoLeft} alt="logoLeft" />
              </a>
            </div>
          )}
          {!branding && (
            <a
              href="/"
              className="logo-href computer large screen widescreen only"
              style={{ paddingTop: '20px' }}
            >
              <img className="logo-header" src={logoLeft} alt="logoLeft" />
            </a>
          )}

          <SelectedPlayerComponent {...{ data, embedSettings }} />

          {data.title && <figcaption>{data.title}</figcaption>}
          {branding && (
            <div className="overlay">
              <h1>
                <strong>Think, play, participate</strong>
              </h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ViewBody.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewBody;
